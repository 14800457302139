<template>
    
    <v-tour :name="name" :steps="steps" :options="options" :callbacks="callbacks"/>

</template>

<script>

export default {
  props: {
    name: {
      type: String
    },
    steps: {
      type: Array,
      required: true,
    },
    delay: {
      type: Number,
      default () { return 1000 }
    },
    onStepFunction: {
      type: Function
    }
  },
  data () {
    return {
      options: {
        highlight: true,
        startTimeout: this.delay,
        labels: {
          buttonSkip: this.$tc('m.remindLater'),
          buttonPrevious: this.$tc('m.previous'),
          buttonNext: this.$tc('m.next'),
          buttonStop: this.$tc('m.finish')
        }
      },
      callbacks: {
        onFinish: this.onFinish,
        onSkip: this.onSkip,
        onStart: this.onStart,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep
      }
    }
  },
  mounted () {
    if (!localStorage.getItem(`tour-${this.name}`)) {
      this.$tours[this.name].start()
    }
  },
  methods: {
    onFinish () {
      localStorage.setItem(`tour-${this.name}`, true)
    },
    onSkip () {
      localStorage.setItem(`tour-${this.name}`, true)
    },
    onStart () {
      if (this.onStepFunction) { this.onStepFunction(0) }
    },
    onNextStep (s) {
      if (this.onStepFunction) { this.onStepFunction(s + 1) }
    },
    onPreviousStep (s) {
      if (this.onStepFunction) { this.onStepFunction(s - 1) }
    }
  },
  components: {
  }
}
</script>

<style scoped>
.v-tour ::v-deep(.v-step__button) {
    min-width: 31%
}

.v-tour ::v-deep(.v-step) {
  background-color: #343a40 !important;
  border-left: 8px solid var(--theme-color);
}
</style>
