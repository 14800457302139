<template>

<svg
   class="janby-imago"
   :style="`height:${height}`"
   version="1.1"
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xml:space="preserve"
   viewBox="0 0 192 192">

    <path
     d="M 90.713134,0.78256478 V 76.918563 H 14.577136 V 96.287896 H 90.713134 110.0838 V 76.918563 0.78256478 Z"
     style="fill:var(--theme-color)"
     id="imago1"/>

    <path
     d="M 90.713134,191.22654 H 110.0838 V 115.08921 H 90.713134 Z"
     style="fill:var(--theme-color)"
     id="imago2"/>

</svg>

</template>

<script>

export default {
  props: {
    height: {
      type: String,
      default () { return '24px' }
    }
  }
}
</script>

<style scoped>

  svg.janby-imago path {
    transition: color 1s ease;
  }

</style>
