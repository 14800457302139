import ApiController from '../api.controller'

export default {
  async login (user, filter = {}, options = {}) {
    const data = await ApiController.fetchWithoutJwt(`/api/v0/auth/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ user , provider: { id: 'janby' } }), uiredirect: 0 })
    if (data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async logout (filter = {}, options = {}) {
    const data = await ApiController.fetchWithoutJwt(`/api/v0/auth/user?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'DELETE', uiredirect: 0 })
    localStorage.removeItem('jwt-session')
    return data
  },

  async getAuthMethods (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/auth/identity/methods?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async recoverPassword (email, filter = {}, options = {}) {
    return ApiController.fetchWithoutJwt(`/api/v0/request/identity/password?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ identity: { email } }), uiredirect: 0 })
  },

  async getJWTData (filter = {}, options = {}) {
    return ApiController.fetchWithJwt(`/api/v0/auth/user/jwt/plain?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, options)
  },

  async mergetToCurrentJWT (add, options = {}) {
    const data = await ApiController.fetchWithJwt('/api/v0/auth/user/jwt/add',  { ...options, method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ add }) })
    if (data.jwt){ localStorage.setItem('jwt-session', JSON.stringify(data.jwt)) }
    return data
  },

  async getRequest (requestType, requestId, filter = {}, options = {}) {
    return ApiController.fetchWithoutJwt(`/api/v0/request/${requestType}/${requestId}?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, uiredirect: 0 })
  },

  async confirmRequest (request, extra = {}, filter = {}, options = {}) {
    return ApiController.fetchWithoutJwt(`/api/v0/request/confirm?${Object.entries(filter).map(f => `${f[0]}=${encodeURIComponent(f[1])}`).join('&')}`, { ...options, method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ request, ...extra }), uiredirect: 0 })
  },
}
