import convert from 'convert-units'
import moment from 'moment-timezone'
import { isProxy , toRaw } from 'vue'
import i18n from '../i18n/i18n'
import UnitController from './unit.controller'

export default {
  
  parseToMoment (str) { // Datetime as string
    const timeZone = this.getTimezone()
    let momentDate = moment(str || new Date().toISOString())
    momentDate = moment.tz(momentDate, timeZone)
    return momentDate
  },

  getTimezone () {
    // Check if queryParams contains the timezone
    let timeZone = null
    const urlParams = window.location.href.split('?').length > 1 ? new URLSearchParams(window.location.href.split('?')[1]) : new URLSearchParams()
    if (urlParams.has('timeZone')) { timeZone = urlParams.get('timeZone') }
    // Test timezone
    if (!moment.tz.zone(timeZone)) { timeZone = null }
    timeZone = timeZone || localStorage.getItem('timeZone')
    if (!moment.tz.zone(timeZone)) { timeZone = null }
    // Otherwise try to guess it
    return timeZone || moment.tz.guess()
  },

  getTime (str) { // Datetime as string
    return this.parseToMoment(str).valueOf()
  },

  getDisplayTzToServerHourOffset () {
    const serverTz = moment.utc()
    const tz = localStorage.getItem('timeZone') || moment.tz.guess()
    const offset = moment.tz.zone(tz).utcOffset(serverTz) / 60 // From TZ to SERVER
    return offset
  },

  getServerToDisplayTzHourOffset () {
    const serverTz = moment.utc()
    const tz = localStorage.getItem('timeZone') || moment.tz.guess()
    const offset = moment.tz.zone(tz).utcOffset(serverTz) / -60 // From SERVER to TZ
    return offset
  },

  formatDatetime (str) { // Datetime as string
    if (!str) { return null }
    const date = this.parseToMoment(str)
    return date
  },

  formatDatetimeToText (str, format = null) { // Datetime as string
    if (!str) { return null }
    const date = this.formatDatetime(str)
    if (format) {
      return date.format(format)
    } else {
      return date.calendar(null, {
        lastDay: '[Yesterday] - HH:mm',
        sameDay: '[Today] - HH:mm',
        nextDay: '[Tomorrow] - HH:mm',
        nextWeek: 'dddd - HH:mm',
        sameElse: 'L - HH:mm'
      })
    }
  },

  getTimeZones () {
    return moment.tz.names()
  },

  getDateRangesInIncrements (startDate, endDate, timeUnit = 'hour') {
    const ranges = []
    let iterator = startDate
    while (iterator <= endDate) {
      ranges.push(this.parseToMoment(iterator).startOf(timeUnit).toDate())
      iterator = this.parseToMoment(iterator).add(1, timeUnit).toDate()
    }
    return ranges
  },

  isNumber (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  },

  getMostSpecificUnit () {
    let units = []
    for (let i = 0; i < arguments.length; i++) {
      units.push(arguments[i])
    }
    units = units.filter(u => u != null).filter(u => u != undefined).filter(u => u != '')
    if (units.length <= 0) { return null }
    
    let mostSpecific = units[0]
    for (let i = 1; i < units.length; i++) {
      const u = units[i]
      if (!u) { continue }
      if (u === mostSpecific) { continue }
      if (!mostSpecific) { mostSpecific = u; continue }
      if (mostSpecific.includes(u)) { continue }
      if (u.includes(mostSpecific)) { mostSpecific = u; continue }
    }
    return mostSpecific
  },

  getUnitName (type, singularPlural = 1) {
    if (!type) { return null }
    if (!type.endsWith('/')) { type += '/' } // fallback
    if (type.includes('weight')) { type = type.replace('weight', 'mass') } // TODO REMOVE ONE DAY
    switch (type) {
      // TEMPERATURES
      case 'temperature/':
      case UnitController.TEMPERATURE: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), UnitController.TEMPERATURE)
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/temperature/', singularPlural) }
      }
      // MASS
      case 'weight/':
      case 'mass/': 
      case UnitController.MASS: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), UnitController.MASS)
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/mass/', singularPlural) }
      }
      // LENGTH
      case 'length/':
      case UnitController.LENGTH: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), UnitController.LENGTH)
        if (lc !== type) { return this.getUnitName(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/length/', singularPlural) }
      }
      default:
        return i18n.global.tc('m.' + type, singularPlural)
    }
  },

  getNormalizedUnitType (unit) {
    const bases = UnitController.getUnitsStructure()
    for (let key in bases) {
      if (bases[key] && bases[key].includes(unit)) {
        return key
      }
    }
    return unit
  },

  getUnitText (type, singularPlural) {
    if (!type) { return '' }
    if (!type.endsWith('/')) { type += '/' } // fallback
    if (type.includes('weight')) { type = type.replace('weight', 'mass') } // TODO REMOVE ONE DAY

    switch (type) {

      // TEMPERATURE
      case 'temperature/':
      case UnitController.TEMPERATURE: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), UnitController.TEMPERATURE_CELSIUS)
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/temperature/', singularPlural) }
      }
      case 'C/':
      case UnitController.TEMPERATURE_CELSIUS:
        return 'ºC'
      case 'F/':
      case UnitController.TEMPERATURE_FAHRENHEIT:
        return 'ºF'
      case 'K/':
      case UnitController.TEMPERATURE_KELVIN:
        return 'ºK'

      // TIME
      case 'time/':
      case UnitController.TIME:
      case UnitController.TIME_DURATION:
      case 's/':
      case UnitController.TIME_SECOND:
        return 's'
      case 'min/':
      case UnitController.TIME_MINUTE:
        return 'min'
      case 'h/':
      case UnitController.TIME_HOUR:
        return 'h'
      case 'd/':
      case UnitController.TIME_DAY:
        return 'd'
      case 'w/':
      case UnitController.TIME_WEEK:
        return 'w'
      case 'm/':
      case UnitController.TIME_MONTH:
        return 'm'
      case 'y/':
      case UnitController.TIME_YEAR:
        return 'y'

      // MASS
      case 'weight/':
      case 'mass/': // In grams
      case UnitController.MASS: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), UnitController.MASS_GRAM)
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/mass/', singularPlural) }
      }
      case 'g/':
      case UnitController.MASS_GRAM:
        return 'g'
      case 'kg/':
      case UnitController.MASS_KILOGRAM:
        return 'kg'
      case 'mg/':
      case UnitController.MASS_MILLIGRAM:
        return 'mg'
      case 'oz/':
      case UnitController.MASS_OUNCE:
        return 'oz'
      case 'lb/':
      case UnitController.MASS_POUND:
        return 'lb'

      // LENGTH
      case 'length/':
      case UnitController.LENGTH: {
        const lc = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), UnitController.LENGTH_METER)
        if (lc !== type) { return this.getUnitText(lc, singularPlural) } else { return i18n.global.tc('m.janby/unit/length/', singularPlural) }
      }
      case 'cm/':
      case UnitController.LENGTH_CENTIMETER:
        return 'cm'
      case 'm/':
      case UnitController.LENGTH_METER:
        return 'm'
      case 'km/':
      case UnitController.LENGTH_KILOMETER:
        return 'km'
      case 'in/':
      case UnitController.LENGTH_INCH:
        return 'in'
      case 'ft/':
      case UnitController.LENGTH_FOOT:
        return 'ft'
      case 'yd/':
      case UnitController.LENGTH_YARD:
        return 'yd'
      case 'mi/':
      case UnitController.LENGTH_MILE:
        return 'mi'

      // UNIT
      case 'unit/':
      case UnitController.INDIVIDUAL_UNIT:
      case 'serving/':
      case UnitController.SERVING:
      case 'slice/':
      case UnitController.UNIT_SLICE:
      case 'leaf/':
      case UnitController.UNIT_LEAF:
      case 'piece/':
      case UnitController.UNIT_PIECE:
      case 'cup/':
      case UnitController.UNIT_CUP:
      case 'tablespoon/':
      case UnitController.UNIT_TABLESPOON:
      case 'teaspoon/':
      case UnitController.UNIT_TEASPOON:
      case 'calory/':
      case UnitController.UNIT_CALORY:
      default:
        return i18n.global.tc('m.' + type, singularPlural)
    }
  },

  // TEMPERATURE

  janbyTemperatureUnitToLib (tempetatureUnit) {
    if (!tempetatureUnit.endsWith('/')) { tempetatureUnit += '/' } // fallback
    switch (tempetatureUnit) {
      case 'temperature/':
      case UnitController.TEMPERATURE:
      case 'celsius/':
      case 'C/':
      case UnitController.TEMPERATURE_CELSIUS:
        return 'C'
      case 'fahrenheit/':
      case 'F/':
      case UnitController.TEMPERATURE_FAHRENHEIT:
        return 'F'
      case 'kelvin/':
      case 'K/':
      case UnitController.TEMPERATURE_KELVIN:
        return 'K'
    }
    alert('Unknown temperature unit ' + tempetatureUnit)
    return tempetatureUnit
  },

  formatTemperature (value, decimals = 1, valueUnit = UnitController.TEMPERATURE_CELSIUS) { // Default in celsius always
    if (value === undefined || value === null || isNaN(value)) { return null }
    const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), UnitController.TEMPERATURE)
    const converted = convert(value).from(this.janbyTemperatureUnitToLib(valueUnit)).to(this.janbyTemperatureUnitToLib(temperatureUnit))
    return this.roundDecimals(converted, decimals)
  },

  formatTemperatureToText (value, decimals = 1, valueUnit = UnitController.TEMPERATURE_CELSIUS) { // Default in celsius always
    if (value === undefined || value === null || isNaN(value)) { return null }
    const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), UnitController.TEMPERATURE)
    return this.formatTemperature(value, decimals, valueUnit) + ' ' + this.getUnitText(temperatureUnit)
  },

  convertTemperature (value, fromUnit, toUnit) {
    if (value === undefined || value === null || isNaN(value)) { return value }
    const converted = convert(value).from(this.janbyTemperatureUnitToLib(fromUnit)).to(this.janbyTemperatureUnitToLib(toUnit))
    return converted
  },

  // WEIGHT

  janbyWeightUnitToLib (massUnit) {
    if (!massUnit.endsWith('/')) { massUnit += '/' } // fallback
    if (massUnit.includes('weight')) { massUnit = massUnit.replace('weight', 'mass') } // TODO REMOVE ONE DAY
    switch (massUnit) {
      case 'mass/':
      case UnitController.MASS:
      case 'gram/':
      case 'g/':
      case UnitController.MASS_GRAM:
        return 'g'
      case 'milligram/':
      case 'mg/':
      case UnitController.MASS_MILLIGRAM:
        return 'mg'
      case 'kilogram/':
      case 'kg/':
      case UnitController.MASS_KILOGRAM:
        return 'kg'
      case 'ounce/':
      case 'oz/':
      case UnitController.MASS_OUNCE:
        return 'oz'
      case 'pound/':
      case 'lb/':
      case UnitController.MASS_POUND:
        return 'lb'
    }
    alert('Unknown mass unit ' + massUnit)
    return massUnit
  },

  formatWeight (value, decimals = 2, valueUnit = UnitController.MASS_GRAM) { // Default in grams always
    if (value === undefined || value === null || isNaN(value)) { return null }
    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), UnitController.MASS)
    return this.roundDecimals(convert(value).from(this.janbyWeightUnitToLib(valueUnit)).to(this.janbyWeightUnitToLib(weightUnit)), decimals)
  },

  formatWeightToText (value, decimals = 2, valueUnit = UnitController.MASS_GRAM) { // Default in grams always
    if (value === undefined || value === null || isNaN(value)) { return null }
    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), UnitController.MASS)
    return this.formatWeight(value, decimals, valueUnit) + ' ' + this.getUnitText(weightUnit)
  },

  convertWeight (value, fromUnit, toUnit) {
    if (value === undefined || value === null || isNaN(value)) { return value }
    const converted = convert(value).from(this.janbyWeightUnitToLib(fromUnit)).to(this.janbyWeightUnitToLib(toUnit))
    return converted
  },

  // LENGTH

  janbyLengthUnitToLib (lengthUnit) {
    if (!lengthUnit.endsWith('/')) { lengthUnit += '/' } // fallback
    switch (lengthUnit) {
      case 'length/':
      case UnitController.LENGTH:
      case 'meter/':
      case 'm/':
      case UnitController.LENGTH_METER:
        return 'm'
      case 'centimeter/':
      case 'cm/':
      case UnitController.LENGTH_CENTIMETER:
        return 'cm'
      case 'millimeter/':
      case 'mm/':
      case UnitController.LENGTH_MILLIMETER:
        return 'mm'
      case 'kilometer/':
      case 'km/':
      case UnitController.LENGTH_KILOMETER:
        return 'km'
      case 'inch/':
      case 'in/':
      case UnitController.LENGTH_INCH:
        return 'in'
      case 'foot/':
      case 'ft/':
      case UnitController.LENGTH_FOOT:
        return 'ft'
      case 'yard/':
      case 'yd/':
      case UnitController.LENGTH_YARD:
        return 'yd'
      case 'mile/':
      case 'mi/':
      case UnitController.LENGTH_MILE:
        return 'mi'
    }
    alert('Unknown length unit ' + lengthUnit)
    return lengthUnit
  },

  formatLength (value, decimals = 2, valueUnit = UnitController.LENGTH_METER) { // Default in meters always
    if (value === undefined || value === null || isNaN(value)) { return null }
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), UnitController.LENGTH)
    return this.roundDecimals(convert(value).from(this.janbyLengthUnitToLib(valueUnit)).to(this.janbyLengthUnitToLib(lengthUnit)), decimals)
  },

  formatLengthToText (meters, decimals = 2, valueUnit = UnitController.LENGTH_METER) { // Given in meters always
    if (meters === undefined || meters === null || isNaN(value)) { return null }
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), UnitController.LENGTH)
    return this.formatLength(meters, decimals, valueUnit) + ' ' + this.getUnitText(lengthUnit)
  },

  convertLength (value, fromUnit, toUnit) {
    if (value === undefined || value === null || isNaN(value)) { return value }
    const converted = convert(value).from(this.janbyLengthUnitToLib(fromUnit)).to(this.janbyLengthUnitToLib(toUnit))
    return converted
  },

  // DURATION

  formatDurationToText (value, decimals = 0, valueUnit = UnitController.TIME_SECOND) { // Default in seconds always
    const duration = moment.duration(value, this.janbyTimeUnitToLib(valueUnit))

    const months = Math.floor(duration.asMonths())
    let days = duration.days()
    if (months > 0) {
      return `${months}${' '+i18n.global.tc('m.janby/unit/time/m/', months)} ${days ? days + ' '+i18n.global.tc('m.janby/unit/time/d/', 2)+' ' : ''} (${Math.floor(duration.asDays())} ${i18n.global.tc('m.janby/unit/time/d/', 2)})`
    }

    days = Math.floor(duration.asDays())
    const hours = duration.hours()
    if (days > 0) {
      return `${days}${' '+i18n.global.tc('m.janby/unit/time/d/', days)} ${hours ? hours + ' '+i18n.global.tc('m.janby/unit/time/h/', 2)+' ' : ''}`
    }

    const mins = duration.minutes()
    if (hours > 0) {
      return `${hours}${' '+i18n.global.tc('m.janby/unit/time/h/', hours)} ${mins ? mins + ' '+i18n.global.tc('m.janby/unit/time/min/', 2)+' ' : ''}`
    }

    const secs = duration.seconds()
    if (mins > 0) {
      return `${mins ? mins + ' '+i18n.global.tc('m.janby/unit/time/min/', mins)+' ' : ''}${secs ? secs + ' '+i18n.global.tc('m.janby/unit/time/s/', secs) : ''}`
    }

    return `${secs > -1 ? secs + ' '+i18n.global.tc('m.janby/unit/time/s/', secs) : ''}`
  },

  formatDurationToCustomizableText (value, showD = true, showH = true, showM = true, showS = true, valueUnit = UnitController.TIME_SECOND) { // Default in seconds always  
    const duration = moment(0).add(value, this.janbyTimeUnitToLib(valueUnit))
    const difference = moment.duration(duration.diff(moment(0)))
    const d = Math.floor(difference.as('days'))
    const h = Math.floor(difference.as('hours')) % 24
    const m = Math.floor(difference.as('minutes')) % 60
    const s = Math.floor(difference.as('seconds')) % 60
    let str = ''
    if (d >= 0 && showD) { str += d + ' ' + i18n.global.tc('m.janby/unit/time/d/', d)+' ' }
    if (h >= 0 && showH) { str += h + ' ' + i18n.global.tc('m.janby/unit/time/h/', h)+' ' }
    if (m >= 0 && showM) { str += m + ' ' + i18n.global.tc('m.janby/unit/time/min/', m)+' ' }
    if ((s >= 0 || (m <= 0 && h <= 0 && d <= 0)) && showS) { str += s + ' ' + i18n.global.tc('m.janby/unit/time/s/', s)+' ' }
    return str
  },

  formatDatetimeDifferenceToText (strFrom, strTo, showD = true, showH = true, showM = true, showS = true) { // Date as strings
    if (!strFrom || !strTo) { return null }
    const fromDate = moment(strFrom)
    const toDate = moment(strTo)
    return this.formatDurationToCustomizableText(moment.duration(toDate.diff(fromDate)), showD, showH, showM, showS)
  },

  janbyTimeUnitToLib (timeUnit) {
    if (!timeUnit.endsWith('/')) { timeUnit += '/' } // fallback
    switch (timeUnit) {
      case 'time/':
      case 'duration/':
      case UnitController.TIME:
      case UnitController.TIME_DURATION:
      case 'second/':
      case 'seconds/':
      case 's/':
      case UnitController.TIME_SECOND:
        return 'seconds'
      case 'minute/':
      case 'minutes/':
      case 'min/':
      case UnitController.TIME_MINUTE:
        return 'minutes'
      case 'hour/':
      case 'hours/':
      case 'h/':
      case UnitController.TIME_HOUR:
        return 'hours'
      case 'day/':
      case 'days/':
      case 'd/':
      case UnitController.TIME_DAY:
        return 'days'
      case 'week/':
      case 'weeks/':
      case 'w/':
      case UnitController.TIME_WEEK:
        return 'weeks'
      case 'month/':
      case 'months/':
      case 'm/':
      case UnitController.TIME_MONTH:
        return 'months'
    }
    alert('Unknown time unit ' + timeUnit)
    return timeUnit
  },

  // OTHERS

  formatNumberToText (unitary, decimals, text) { // Given in numeric always
    return `${this.roundDecimals(unitary, decimals)}${text}`
  },

  // UNIVERSAL FORMATTER
  toFixed (number, decimals = 1) {
    if (!number) { return 0 }
    if (!number.toFixed) { return number }
    if (number % 1 == 0) { return number }
    return number.toFixed(decimals)
  },

  formatAny (value, type, decimals = 2) {
    if (value === null || value === undefined || isNaN(value) || !type) { return null }
    if (!type.endsWith('/')) { type += '/' } // fallback
    if (type.includes('weight')) { type = type.replace('weight', 'mass') } // TODO REMOVE ONE DAY
    switch (type) {
      case 'temperature/':
      case UnitController.TEMPERATURE:
      case UnitController.TEMPERATURE_CELSIUS:
      case UnitController.TEMPERATURE_FAHRENHEIT:
      case UnitController.TEMPERATURE_KELVIN:
        return this.formatTemperature(value, decimals, type)
      case 'mass/':
      case 'weight/':
      case UnitController.MASS:
      case UnitController.MASS_GRAM:
      case UnitController.MASS_KILOGRAM:
      case UnitController.MASS_OUNCE:
      case UnitController.MASS_POUND:
        return this.formatWeight(value, decimals, type)
      case 'length/':
      case UnitController.LENGTH:
      case UnitController.LENGTH_METER:
      case UnitController.LENGTH_CENTIMETER:
      case UnitController.LENGTH_MILLIMETER:
      case UnitController.LENGTH_KILOMETER:
      case UnitController.LENGTH_INCH:
      case UnitController.LENGTH_FOOT:
      case UnitController.LENGTH_YARD:
      case UnitController.LENGTH_MILE:
        return this.formatLength(value, decimals, type)
      case 'time/':
      case 'duration/':
      case UnitController.TIME:
      case UnitController.TIME_DURATION:
      case UnitController.TIME_SECOND:
      case UnitController.TIME_MINUTE:
      case UnitController.TIME_HOUR:
      case UnitController.TIME_DAY:
      case UnitController.TIME_WEEK:
      case UnitController.TIME_MONTH:
      case UnitController.TIME_YEAR:
        return this.roundDecimals(value, 0)
      default:
        console.log('Unknown type', type)
        return this.roundDecimals(value, decimals)
    }
  },

  formatAnyToText (value, type, decimals = 2, categories = []) {
    if (value === null || value === undefined || isNaN(value) || !type) { return '_' }

    const weightUnit = this.getMostSpecificUnit(localStorage.getItem('weightUnit'), UnitController.MASS)
    const temperatureUnit = this.getMostSpecificUnit(localStorage.getItem('temperatureUnit'), UnitController.TEMPERATURE)
    const lengthUnit = this.getMostSpecificUnit(localStorage.getItem('lengthUnit'), UnitController.LENGTH)

    if (!type.endsWith('/')) { type += '/' } // fallback
    if (type.includes('weight')) { type = type.replace('weight', 'mass') } // TODO REMOVE ONE DAY

    switch (type) {
      case 'temperature/':
      case UnitController.TEMPERATURE:
      case UnitController.TEMPERATURE_CELSIUS:
      case UnitController.TEMPERATURE_FAHRENHEIT:
      case UnitController.TEMPERATURE_KELVIN:
        return this.formatTemperatureToText(value, decimals, type)
      
      case 'time/':
      case 'duration/':
      case UnitController.TIME:
      case UnitController.TIME_DURATION:
      case UnitController.TIME_SECOND:
      case UnitController.TIME_MINUTE:
      case UnitController.TIME_HOUR:
      case UnitController.TIME_DAY:
      case UnitController.TIME_WEEK:
      case UnitController.TIME_MONTH:
      case UnitController.TIME_YEAR:
        return this.formatDurationToText(value, 0, type)

      case 'mass/':
      case 'weight/':
      case UnitController.MASS:
      case UnitController.MASS_GRAM:
      case UnitController.MASS_KILOGRAM:
      case UnitController.MASS_OUNCE:
      case UnitController.MASS_POUND:
        return this.formatWeightToText(value, decimals, type)

      case 'length/':
      case UnitController.LENGTH:
      case UnitController.LENGTH_METER:
      case UnitController.LENGTH_CENTIMETER:
      case UnitController.LENGTH_MILLIMETER:
      case UnitController.LENGTH_KILOMETER:
      case UnitController.LENGTH_INCH:
      case UnitController.LENGTH_FOOT:
      case UnitController.LENGTH_YARD:
      case UnitController.LENGTH_MILE:
        return this.formatLengthToText(value, decimals, type)

      case 'unit/':
      case UnitController.INDIVIDUAL_UNIT:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/', 2))

      case 'serving/':
      case UnitController.SERVING:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/serving/', 2))

      case 'piece/':
      case UnitController.UNIT_PIECE:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/piece/', 2))

      case 'cup/':
      case UnitController.UNIT_CUP:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/cup/', 2))

      case 'tablespoon/':
      case UnitController.UNIT_TABLESPOON:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/tablespoon/', 2))

      case 'teaspoon/':
      case UnitController.UNIT_TEASPOON:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/teaspoon/', 2))

      case 'slice/':
      case UnitController.UNIT_SLICE:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/slice/', 2))

      case 'leaf/':
      case UnitController.UNIT_LEAF:
        return this.formatNumberToText(value, decimals, ' ' + i18n.global.tc('m.janby/unit/leaf/', 2))

      case 'calory/':
      case UnitController.UNIT_CALORY:
        return this.formatNumberToText(value, decimals, ' cal')

      case 'category':
        return categories && categories[value] ? categories[value] : value

      default:
        console.log('Unknown type', type)
        return this.roundDecimals(value, decimals)
    }
  },

  // HELPERS
  roundDecimals (value, decimals = 2) {
    if (isNaN(value)) { value = Number(value) }
    return Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  },

  weekStartDate (referenceDate = new Date()) {
    let dateElem = new Date(referenceDate)
    dateElem = new Date(dateElem.setHours(0, 0, 0))
    const day = dateElem.getDay() || 7 // Get current day number, converting Sunday to 7
    if (day !== 1) { // Only manipulate the date if it isn't Monday
      dateElem.setHours(-24 * (day - 1)) // Set the hours to day number minus 1
    }
    return dateElem
  },

  weekEndDate (referenceDate = new Date()) {
    let dateElem = new Date(referenceDate)
    dateElem = new Date(dateElem.setHours(23, 59, 59))
    const day = dateElem.getDay() || 7 // Get current day number, converting Sunday to 7
    if (day !== 7) { // Only manipulate the date if it isn't Sunday
      dateElem.setHours(24 * (7 - day)) // Set the hours to difference for reaching sunday
    }
    return dateElem
  },

  monthStartDate (referenceDate = new Date()) {
    const dateElem = new Date(referenceDate)
    return new Date(dateElem.getFullYear(), dateElem.getMonth(), 1)
  },

  monthEndDate (referenceDate = new Date()) {
    const dateElem = new Date(referenceDate)
    return new Date(dateElem.getFullYear(), dateElem.getMonth() + 1, 0)
  },

  mergeObjects(target = {}, source = {}, setIfNull = true) {

    if (!target) { target = {} }
    if (isProxy(target)) { target = toRaw(target) }
    if (isProxy(source)) { source = toRaw(source) }

    for (const key in source) {

      // Is null
      if (source[key] === null) {
        target[key] = setIfNull ? source[key] : target[key]
      }

      // Is undefined
      else if (source[key] === undefined) {
        target[key] = target[key]
      }

      // Is string
      else if (typeof source[key] === 'string' || typeof target[key] === 'string') {
        target[key] = source[key]
      }

      // Is number
      else if (typeof source[key] === 'number' || typeof target[key] === 'number') {
        target[key] = source[key]
      }

      // Is array
      else if (Array.isArray(source[key])) {
        if (!Array.isArray(target[key])) { target[key] = [] }
        target[key] = target[key].concat(source[key])
      }
      
      // Is object
      else if (typeof source[key] === 'object') {
        if (typeof target[key] !== 'object') { target[key] = {} }
        target = { ...target, [key] : this.mergeObjects(target[key], source[key]) }
      }
      
      else {
        target[key] = source[key]
      }
    }

    return target
  },

  /* async mergeObjects (obj1, obj2 = {}) {
    return new Promise(async (resolve, reject) => {
        
      if (!obj1 && !obj2) { return resolve(null) }
      if (!obj1 && obj2) { return resolve(obj2) }
      if (obj1 && !obj2) { return resolve(obj1) }

      SWorker.run((obj1, obj2) => {

        const merged = {}
        for(const k in obj1 || {}) {
          if(merged[k] === undefined || merged[k] === null) { merged[k] = obj1[k] }
          if(obj1[k] === undefined) { continue }
          // Check if is string
          else if (typeof obj1[k] === 'string') { merged[k] = obj1[k] }
          // Check if object
          else if (typeof obj1[k] === 'object' && !Array.isArray(obj1[k])){ merged[k] = { ...(merged[k] || {}) , ...(obj1[k] || {}) } }
          // Check array
          else if (typeof obj1[k] === 'object' && Array.isArray(obj1[k])) { merged[k] = [...(merged[k] || []), ...(obj1[k] || [])] }
          // Other value
          else { merged[k] = obj1[k] }
        }
        for(const k in obj2 || {}) {
          if(merged[k] === undefined || merged[k] === null) { merged[k] = obj2[k] }
          if(obj2[k] === undefined) { continue }
          // Check if is string
          else if (typeof obj2[k] === 'string') { merged[k] = obj2[k] }
          // Check if object
          else if (typeof obj2[k] === 'object' && !Array.isArray(obj2[k])){ merged[k] = { ...(merged[k] || {}) , ...(obj2[k] || {}) } }
          // Check array
          else if (typeof obj2[k] === 'object' && Array.isArray(obj2[k])) { merged[k] = [...(merged[k] || []), ...(obj2[k] || [])] }
          // Other value
          else { merged[k] = obj2[k] }
        }

        return merged
      }, [obj1, obj2])
      .then(resolve)
      .catch(console.error) // logs any possible error

    })
  }, */

  getPrintOptions () {
    return {
      id: 'printable',
      extraCss: `/print.css`,
      previewTitle: 'JANBY Digital Kitchen - Cloud', 
      popTitle: 'JANBY Digital Kitchen - Cloud', 
    }
  },
}
