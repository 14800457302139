<template>

    <div v-if="visible" class="position-fixed text-center fixed-status-message" :style="position">
        <div class="py-1 px-2 rounded-top text-white d-flex justify-content-between align-content-center">
            <i class="material-icons d-block mr-4">priority_high</i>
            <small>We are currently working to make things better, the site may be down for some seconds.</small>
            <b-button variant="light" size="sm" class="p-0 ml-4" @click="visible = false"><i class="material-icons">close</i></b-button>
        </div>
    </div>

</template>

<script>

export default {
  props: {
    me: {
      type: Object
    },
    layout: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    position () {
      if (this.layout && this.layout.mobile) {
        return { left: '0px', width: '80vw' }
      }
      if (this.layout && this.layout.sidenav === 1) {
        return { left: '20vw', width: '60vw' }
      }
      if (this.layout && this.layout.sidenav === 2) {
        return { right: '10vw', width: 'calc(80vw - 300px)' }
      }
      return { right: '10vw', width: 'calc(80vw - 300px)' }
    }
  }
}
</script>

<style scoped>
    .fixed-status-message {
        z-index: 1;
        bottom:-128px;
        animation: status-message-animation 1s linear;
        animation-delay: 5s;
        animation-fill-mode: forwards;
    }

    .rounded-top {
        background: var(--theme-color);
    }

    @keyframes status-message-animation {
    from { bottom:-128px }
    to { bottom:0px }
    }
</style>
