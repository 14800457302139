<template>

      <b-dropdown id="app-location-picker" v-if="locations" :class="`p-0 m-0 d-inline fade-animation theme-title ${location ? '' : 'pulse-animation'} ${layout.mobile ? 'mobile' : ''} ${showPermissions ? 'not-collapsed' : 'collapsed'}`" split lazy split-variant="link" variant="link" split-class="text-white" toggle-class="text-white" :split-to="location ? `/location/${location.id}` : '/'">

        <template v-slot:button-content>

          <div v-show="!location">{{$tc('m.selectEntity', 1, { entity: $tc('m.Location') })}}</div>

          <div v-if="location && !isViewFull" class="text-truncate app-location-picker-location" :title="`${location.name} - ${$tc('m.' + (location.type || 'janby/location/' ))}`">{{location.name || $tc('m.Location') }}</div>

          <div v-if="organization && isViewFull" class="text-truncate app-location-picker-location"><i class="material-icons mr-2 d-inline">admin_panel_settings</i>{{organization.name || $tc('m.Organization')}}</div>

        </template>

        <!-- LOCATION MODE -->
        <b-dropdown-form v-if="location && permissions && !isViewFull">
            <DataInfoCard :name="location.name" :description="$tc('m.' + (location.type || 'Location'))" color="var(--theme-color)" :image="location.image" imageWidth="32px"/>
            
            <b-button v-if="permissions.RO >= 4" class="mb-0 w-100" variant="dark" @click="viewFullOrganizationOn()">{{$tc('m.enableEntity', 1, { entity: $tc('m.isViewFull') })}}</b-button>
        </b-dropdown-form>

        <!-- VIEW FULL ORGANIZATION MODE -->
        <b-dropdown-form v-if="organization && permissions && isViewFull">
            <DataInfoCard :name="organization.name" :description="$tc('m.isViewFullEnabled_desc')" color="var(--theme-color)" :image="organization.image" imageWidth="32px"/>

            <b-button pressed class="mb-0 w-100" variant="dark" @click="viewFullOrganizationOff()">{{$tc('m.disableEntity', 1, { entity: $tc('m.isViewFull') })}}</b-button>
        </b-dropdown-form>

        <b-dropdown-divider/>

        <!-- ALL LOCATION -->
        <div v-if="location">
          <b-dropdown-item to="/locations">{{ $tc('m.allEntity', 1, { entity: $tc('m.Location', 2) }) }}</b-dropdown-item>
          <b-dropdown-divider/>
        </div>

        <!-- LOCATION PICKER -->
        <b-dropdown-group v-show="locations.length" :header="$tc('m.selectEntity', 1, { entity: $tc('m.Location') })">
          <div v-for="k in locations" :key="k.id" >
            <LocationPickerList :loggedLocation="location" :location="k" @update="onUpdate"/>
          </div>
        </b-dropdown-group>

        <!-- NO LOCATIONS -->
        <b-dropdown-text v-show="locations.length <= 0">
          <a href="/" class="text-dark">{{ $tc('m.noLocationAllowed') }}. {{ $tc('m.contact_organization_manager') }}</a>
        </b-dropdown-text>

        <!-- LEAVE LOCATION -->
        <div v-if="location">
          <b-dropdown-divider/>
          <b-dropdown-item @click="$emit('update', 'location', null)" to="/">{{ $tc('m.leaveLocation') }}</b-dropdown-item>
        </div>

      </b-dropdown>

</template>

<script>

import OrganizationController from '@/controllers/base/organization.controller.js'
import LocationController from '@/controllers/base/location.controller.js'

import DataInfoCard from '@/views/components/_datacard/DataInfoCard.vue'
import LocationPickerList from '@/views/components/_menu/LocationPickerList'
import PermissionsTable from '@/views/components/permission/PermissionsTable.vue'

export default {
  props: {
    organization: {
      type: Object
    },
    location: {
      type: Object
    },
    permissions: {
      type: Object
    },
    counts: {
      type: Object
    },
    layout: {
      type: Object
    }
  },
  data () {
    return {
      locations: null,
      timeout: null,
      isViewFull: null,
      showPermissions: false
    }
  },
  async mounted () {
    this.isViewFull = this.permissions ? this.permissions.isViewFull || false : false
    this.getLocations()
  },
  methods: {
    async getLocations () {
      // Wait 1 second
      if (this.timeout) { return }

      await new Promise((resolve) => {
        this.timeout = setTimeout(() => {
          this.timeout = null
          resolve()
        }, 1000)
      })

      const filter = { limit: 100, attributes: ['id','name','type','hierarchy'] }
      if (!this.isViewFull) {
        filter.locationTypes = LocationController.showDefaultLocationTypes
      }

      // If no location, get root locations (depth == 1)
      /* if (!this.isViewAll && !this.location) {
        where.depth = 1
      } */

      /* if (!this.isViewAll && this.location) {
        where.parent = this.location.id
      } */

      const data = await LocationController.getPaginatedLocations(filter)
      this.locations = data.locations

      await this.checkSingleLocation()
    },
    async checkSingleLocation () {
      if (!this.location && this.locations && this.locations.length === 1) {
        this.onUpdate('location', this.locations[0])
      }
    },
    async viewFullOrganizationOn () {
      this.$emit('update', 'busy', 1)
      const d = await OrganizationController.setViewFullOrganizationOn()
      if (d.permissions) { this.$emit('update', 'permissions', { ...this.permissions, ...d.permissions }) }
      if (d.jwt) { this.$emit('update', 'jwt', d.jwt) }
      this.$emit('update', 'message', { body: 'The view will show all organization data, regardless of the logged in location', title: this.$tc('m.entityEnabled', 1, { entity: this.$tc('m.isViewFull')}), variant: 'success' })
      this.$emit('update', 'busy', -1)
    },
    async viewFullOrganizationOff () {
      this.$emit('update', 'busy', 1)
      const d = await OrganizationController.setViewFullOrganizationOff()
      if (d.permissions) { this.$emit('update', 'permissions', { ...this.permissions, ...d.permissions }) }
      if (d.jwt) { this.$emit('update', 'jwt', d.jwt) }
      this.$emit('update', 'message', { body: 'The view will only logged in location\'s data', title: this.$tc('m.entityDisabled', 1, { entity: this.$tc('m.isViewFull')}), variant: 'success' })
      this.$emit('update', 'busy', -1)
    },
    async onUpdate (type, data) {
      try {
        switch (type) {
          case 'location': {
            // Already logged in location clicked
            if (this.location && this.location.id === data.id) {
              this.$router.push({ path: `/location/${this.location.id}` })
              return
            }

            this.$emit('update', 'busy', 1)

            const d = await LocationController.loginInLocation(data.id, { counts: true, metas: true })
            if (!d || !d.location) { return location = '/' }
            this.$emit('update', 'location', d.location)
            if (d.permissions) { this.$emit('update', 'permissions', d.permissions) }
            if (d.jwt) { this.$emit('update', 'jwt', d.jwt) }

            this.$emit('update', 'busy', -1)
            break
          }

          default:
            this.$emit('update', 'message', { body: data, title: 'Unknown message', variant: 'danger' })
        }
      } catch (err) {
        this.$emit('update', 'message', { body: err.message, title: 'Error loading location', variant: 'danger' })
      }
    }
  },
  watch: {
    permissions () {
      this.isViewFull = this.permissions ? this.permissions.isViewFull || false : false
      this.getLocations()
    }
  },
  components: {
    DataInfoCard,
    LocationPickerList,
    PermissionsTable
  }
}
</script>

<style scoped>
   #app-location-picker.mobile ::v-deep(a.text-white) {
     padding-left: 4px;
     padding-right: 4px;
  }

  #app-location-picker ::v-deep(.app-location-picker-location) {
    max-width: 30vw
  }

  .dropdown ::v-deep(.btn) {
    margin: 0;
    text-transform: none;
  }

  .dropdown ::v-deep(hr.dropdown-divider) {
    margin: 0;
    width: 100%;
    min-width: 200px;
  }

  .dropdown ::v-deep(.b-dropdown-form) {
    padding: .8rem 1rem
  }

  .dropdown ::v-deep(.b-dropdown-form .custom-control-label) {
    line-height: 1.5
  }
</style>
