<template>

    <b-button v-if="location" variant="link" class="mb-0 text-white d-flex fade-animation" to="/notification/notifications">
        <i v-show="counts && counts.N > 0" :key="location.id + counts.N" class="material-icons bell-animation theme-color">notifications</i>
        <span v-show="counts && counts.N > 0" class="badge badge-dark icon-pill badge-pill right-bottom d-block">{{counts.N}}</span>

        <i v-show="counts && counts.N == 0" class="material-icons text-white">notifications_none</i>
    </b-button>

</template>

<script>

import NotificationController from '@/controllers/notification/notification.controller.js'

export default {
  props: {
    jwt: {
      type: String
    },
    location: {
      type: Object
    },
    permissions: {
      type: Object
    },
    counts: {
      type: Object
    },
    layout: {
      type: Object
    },
    intervalSeconds: {
      type: Number,
      default: 300
    }
  },
  data () {
    return {
      timeout: null
    }
  },
  mounted () {
    this.getNotifications()
  },
  methods: {
    getNotifications () {
      if (!this.permissions || !this.location) { return }
      if (this.timeout) { clearTimeout(this.timeout) }

      this.timeout = setTimeout(async () => {
        try {
          const d = await NotificationController.getPaginatedNotifications({ attributes: ['id', 'name', 'resolved'], limit: 0, by: 'registered', order: 'desc', isPending: true })
          this.$emit('update', 'counts', { N: d.count })
          this.getNotifications() // If crashes do not request anymore
        } catch (e) {}

      }, this.intervalSeconds * 1000)
    }
  },
  watch: {
    jwt (val) {
      if (val) {
        this.getNotifications()
      }
    },
    counts (val) {
      if (val && val.N && val.N < 0) {
        this.getNotifications()
      }
    }
  }
}
</script>
